@use '../abstracts/' as *;

.case{

    margin-top: 1rem;

    h2{
        padding-top: 1rem;
    }

    h3{
        padding-top: 1rem;
    }
    
}

.sidebar{
    display: none;
    max-height: 400px;
    border-radius: 12px;

    @include mq('small'){
        display: block;
    
}
    
    ul{
        padding: 0;
        list-style: none;
        
    }

    
}

.case-body{
    padding: 1.5rem;
    max-width: 65ch;
    margin: 0 auto;

    h2{
        padding-bottom: 1rem;
        margin-left: -1rem;
        font-size: var(--fs-400);
        color: $magenta-3;
        
    }
}

.case-image{
    border-radius: 10px 10px 0 0;
}

.case-content{
    width: 100%;
}

.case{
    blockquote{
        padding-bottom: 2rem;
    }
}

.case-list{
   
    padding: 1rem;
    
}

.list-group{
    background-color: rgba(61, 48, 140, 0.42);
border: 1px solid rgba(250, 157, 48, 0.8);
border-radius: 10px;
}