@use '../abstracts' as *;

.blog-block{
    position: relative;
    border-radius: 12px;
    padding: 2em;
    margin-top: 3em;
    margin-bottom: 6em;
    background-color: $neutral-5;

   
}

.long-block{
    height: fit-content;
    margin-bottom: 1em;
    cursor: pointer;
}

.short-block{
    position: relative;
    height: 5rem;
    margin-bottom: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
}

.short-block:hover{
    cursor: pointer;
}

.short-block::after{
    content: ' more...';
    font-size: large;
    font-weight: 900;
    color: $magenta-3;
    position: absolute;
    right: 0;
    bottom: 0;
    padding-inline: 1em;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(230,230,230,1) 15%);
}