@use '../abstracts' as *;


input[type=text], [type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 2px solid $magenta-5;
    border-radius: 4px;
    resize: vertical;
    box-sizing: border-box;
  }
  
input:focus, input:hover, textarea:focus, textarea:hover {
    border: 2px solid $magenta-2;

}

textarea{
    resize: none;
    overflow-y: scroll;
}

  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
    
  }

  button[type=submit] {
    background-color: $magenta-3;
    color: $neutral-5;
    padding: .5em 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    float: right;
    margin-block: 5px;
    margin-right: 10px;

  }

  .form-container {
    border-radius: 5px;
    background-color: $neutral-4;
    padding: 20px;
    margin-block: 1em;
  }

  
  
  .col-25 {
    float: left;
    width: 25%;
    margin-top: 6px;
  }
  
  .col-75 {
    float: left;
    width: 75%;
    margin-top: 6px;
  }

  .form-row{
    @include mq("small"){
        width: 50ch;
    }
  }

  .form-row:after {
    content: "";
    display: table;
    clear: both;
  }

  @media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
      width: 100%;
      margin-top: 0;
    }
  }