@use "../abstracts" as *;

h1 {
  font-size: var(--fs-800);
  line-height: var(--fs-700);
}

h2 {
  font-size: var(--fs-600);
  line-height: var(--fs-600);
  color: $magenta-3;
  text-transform: uppercase;
  margin-inline: 1rem;
  text-shadow: 2px 2px 1px $neutral-1;
}

h3 {
  font-size: var(--fs-400);
  line-height: var(--fs-300);
  color: $orange-3;
  text-transform: uppercase;
}

h4 {
  font-size: var(--fs-300);
  line-height: car(--fs-300);
  color: $magenta-2;
  text-transform: uppercase;
}

a {
  color: $magenta-3;
  text-decoration: none;
  padding-bottom: 0;
}

a:visited {
  color: $purple-3;
  -webkit-text-stroke: 2px hsl(248deg, 49%, 37%);
}

.caption {
  font-family: "Neon", sans-serif;
  font-size: var(--fs-200);
}

.card {
  h2 {
    color: $blue-2;
    font-size: var(--fs-400);
    line-height: var(--fs-400);
    text-shadow: none;
    white-space: pre-wrap;

    @include mqMax("small") {
      font-size: var(--fs-300);
      line-height: var(--fs-300);
      white-space: normal;
    }

    margin-inline: 0;
  }

  h3 {
    font-size: var(--fs-300);
    line-height: var(--fs-200);
    color: $magenta-3;
    text-transform: uppercase;
    border-bottom: 2px solid $magenta-3;
  }

  h4 {
    color: $blue-2;
  }
}

.card-text {
  font-size: var(--fs-200);
  color: $blue-2;
  margin-bottom: 1rem;
  white-space: normal;
}

.tag {
  text-transform: uppercase;
  font-size: var(--fs-100);
}

.sr-only {
  font-size: 0.2rem;
}

.subtitle h3 {
  color: $purple-3;
  margin: 0 auto;
  text-align: center;
  font-size: var(--fs-500);
  line-height: var(--fs-600);
  margin-block: 0;

  -webkit-text-stroke: 1px $blue-2;
  text-shadow: 2px 2px 1px $neutral-1;
}

.quote-text {
  font-family: "Neon", sans-serif;
  text-shadow: 0 0 3px $magenta-3;
  font-size: var(--fs-400);
  color: $orange-4;
  justify-content: start;
  background-color: transparent;
}

.rotated {
  padding-top: 1rem;
  padding-bottom: 1em;
  margin-left: -20%;
  transform: rotate(-30deg);
  max-width: fit-content;
}



.site-link {
  text-align: justify;
  font-family: "Kanit", sans-serif;
  font-size: var(--fs-600);
  font-weight: 700;
  line-height: var(--fs-700);
  color: $magenta-3;
  border: none;
  text-transform: capitalize;
  text-shadow: 2px 2px 1px $neutral-1;
}

.site-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.side-link{
  text-align: justify;
  font-family: "Kanit", sans-serif;
  font-size: var(--fs-300);
  font-weight: 700;
  line-height: var(--fs-400);
  color: $magenta-3;
  border: none;
  text-transform: capitalize;
  text-shadow: 2px 2px 1px $neutral-1;
  padding-inline: 1rem;
  white-space: nowrap;
  overflow: ellipsis;
}

.side-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.nav-item {
  text-transform: capitalize;
  color: $blue-2;

  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: $orange-3;
  text-shadow: -2px -2px 0 $orange-3, 2px -2px 0 $orange-3, -2px 2px 0 $orange-3,
    2px 2px 0 $orange-3;

  font-size: var(--fs-700);
  font-style: normal;
  font-weight: 700;
  line-height: 105.5%;

  @include mqMax("small") {
    align-self: flex-start;
    padding-left: min(5%, 4rem);
  }

  & > a {
    color: $blue-2;
    text-decoration: none;
    padding-bottom: 0;
  }

  & > a:visited {
    color: $blue-2;
  }

  & > a:hover,
  & > a:focus {
    color: $orange-3;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: $blue-2;
    text-shadow: -1px -1px 0 $blue-2, 1px -1px 0 $blue-2, -1px 1px 0 $blue-2,
      1px 1px 0 $blue-2;
  }
}

h3 > a{

&:visited{
  color: $magenta-3;
  
}

&:hover{
  cursor: default;
}

}

.blog-text {
  color: $blue-2;
  max-width: 75ch;
  margin-left: 2ch;
  padding-inline: var(--fs-100);
}

label {
  text-transform: uppercase;
  font-weight: 600;
  color: $blue-2;
}

button[type="submit"] {
  text-transform: uppercase;
  font-weight: 500;
}

.card-link {
  a {
    color: $magenta-3;
  }

  a:hover,
  a:focus {
    border-bottom: none;
  }

  font-size: var(--fs-500);
  align-self: flex-start;
  margin-inline: auto;
}

.card-cta {
  text-transform: uppercase;
  font-size: --fs-300;

  a {
    color: $neutral-5;
  }

  a:hover {
    color: $orange-5;
  }
}


.case-body {
  blockquote {
    font-family: "PT Sans Caption", sans-serif;
    font-size: var(--fs-200);
    font-weight: 600;
    color: $purple-2;
    background-color: $orange-4;
    padding-inline: 1rem;
    padding-block: 0rem;
    padding-top: 1rem;
    margin: 0;
    border-radius: 10px;
    border: 3px solid $orange-2;

    & blockquote {
      border: 0px solid transparent;
      background-color: $magenta-3;
      color: $orange-3;
    }
  }
}

code{
  color: #000;
  font-family: 'Source Code Pro', monospace;
  font-size: var(--fs-200);
  font-weight: 600;

}

pre{
  font-family: 'Source Code Pro', monospace;
  font-size: var(--fs-200);
  font-weight: 600;
  background-color: $blue-1;
  color: $orange-3;
  text-indent: var(--fs-200);
  padding-inline: .5rem;
  border-radius: 5px;;

code{
  white-space: pre-line;
  color: inherit;
}
}

.hljs-keyword{
  color: $magenta-5;
}

.hljs-built_in{
  color: $magenta-5;
}

.hljs-title{
  color: $orange-4;
}

.hljs-title.function_{
  color: $pink-3;
}

.hljs-params{
  color: $magenta-3;
}

.hljs-property{
  color: $magenta-4;
}

.hljs-attr{
  color: $purple-4;
}

.hljs-string{
  color: $orange-5;
}

.hljs-variable{
  color: $magenta-3;
}

.hljs-number{
  color: $pink-5;
}

.hljs-comment{
  color: $blue-3;
}




.list-group > h4{
  color: $orange-3;
  font-weight: 700;
  font-size: var(--fs-400);
  letter-spacing: .05em;
  line-height: 1rem;
  text-transform: capitalize;
  text-align: center;
  padding-block: var(--fs-200);
}

a.blog-link {
  font-weight: 200;
  color: $purple-1;
}

a.blog-link:visited {
  color: $magenta-2;
}

a.ul-link-l-to-r {
  position: relative;
}

a.ul-link-l-to-r:before {
  content: "";
  position: absolute;

  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

a.ul-link-l-to-r:hover:before {
  visibility: visible;
  transform: scaleX(1);
}
