
@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:wght@500&display=swap');

@font-face {
    font-family: "Neon";
    src: url("assets/NEON____.TTF");
  }


$type-scale: (
    small:(
        100: .53rem,
        200: 1rem,
        300: 1.1rem,
        400: 1.25rem,
        500: 1.5rem,
        600: 2rem,
        700: 3rem,
        800: 6.5rem
    ),
    medium:(
        100: .6rem,
        200: .9rem,
        300: 1.2rem,
        400: 1.4rem,
        500: 2rem,
        600: 2.5rem,
        700: 3.2rem,
        800: 4.5rem
    ),
    large:(
        100: .7rem,
        200: 1rem,
        300: 1.5rem,
        400: 2rem,
        500: 2.8rem,
        600: 3rem,
        700: 4rem,
        800: 8.5rem)
    );