@use "../abstracts/" as *;

.hero,
.tutorial {
  gap: var(--gap-sm);
  grid-column: span 2;
  
  @include mqMax("small") {
    width: 80vw;
  }
}

.side-kick {
  gap: var(--gap-sm);
  width: 100%;
  @include mqMax("small") {
    width: 98vw;
  }
}

.card {
  background-color: $neutral-5;
  border: 2px solid $orange-3;
  border-radius: 10px;
  flex-shrink: 0;
  max-width: fit-content;

  .card-text {
    max-width: 60ch;
    padding: 0.1rem;
    margin-top: 0.5rem;
    line-height: 1.7rem;
    word-break: break-word;
  }

  .card-content {
    display: grid;
    @include mq("small") {
      grid-template-columns: 4fr 1fr;
    }
    @include mqMax("small") {
      grid-template-columns: 1fr;
    }
    padding: {
      top: 0.5rem;
      left: 2rem;
      right: 0;
      bottom: 0.3rem;
    }
    @include mqMax("small") {
      padding: {
        top: 0.5rem;
        left: 0.1rem;
        right: 0;
      }
    }
  }

  .card-condensed {
    display: grid;
    grid-template-columns: 1fr;
    padding: {
      top: 0.5rem;
      left: 1rem;
      right: 1rem;
      bottom: 0.3rem;
    }
    @include mqMax("small") {
      padding: {
        top: 0.5rem;
        left: 0.1rem;
        right: 0;
      }
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 10px 10px 0 0;
    overflow: hidden;

    @include mqMax("small") {
      height: 10rem;
    }

    img {
      object-fit: cover;
      object-position: left top;
    }
  }
  position: relative;
}

.content-group {
  white-space: normal;
  width: 81vw;
  overflow-x: visible;

  @include mq("small") {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    flex-direction: column;
    scroll-snap-type: none;
    overflow-x: visible;
  }
}

.deck-box {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  width: 81vw;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;

  @include mq("small") {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    flex-direction: column;
    scroll-snap-type: none;
    overflow-x: visible;
  }
}

.deck-box div {
  scroll-snap-align: start;
}

.deck-box > .card {
  margin-right: 0;

  @include mqMax("small") {
    width: 80vw;
    margin-right: 1rem;
  }
}

.card-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 48px;

  @include mqMax("small") {
    display: none;
    min-width: 0px;
  }
}

.tag {
  // TODO add media query
  color: $purple-3;
  padding-inline: 0.2rem;
  padding-block: 0.1rem;
  margin-inline: 0.05rem;
  background-color: $orange-3;
  border-radius: 2px;
  font-size: var(--fs-100);
  font-weight: 800;
}

.card-cta {
  border-radius: 5px;
  max-width: fit-content;
  padding: 0.5em 1em;
  margin: 1em 1em 0 0;
  background-color: $magenta-3;
}
