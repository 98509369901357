@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans+Caption&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Source+Code+Pro:wght@500&display=swap");
@font-face {
  font-family: "Neon";
  src: url("assets/NEON____.TTF");
}
/* media query mixin */
/* media query mixin for max-width */
/* Start of text3d mixin */
/* End of text3d mixin */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role=list],
ol[role=list] {
  list-style: none;
}

nav ul, nav ol {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
*::before,
*::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
:root {
  --gap: 2rem;
  --gap-sm: 1rem;
  --fs-100: 0.53rem;
  --fs-200: 1rem;
  --fs-300: 1.1rem;
  --fs-400: 1.25rem;
  --fs-500: 1.5rem;
  --fs-600: 2rem;
  --fs-700: 3rem;
  --fs-800: 6.5rem;
  --bp-small: 40em;
  --bp-medium: 65em;
  --bp-large: 100em;
  --bp-tall: 2000px;
  --bp-short: 675px;
}
@media only screen and (min-width: 40em) {
  :root {
    --fs-100: 0.7rem;
    --fs-200: 1rem;
    --fs-300: 1.5rem;
    --fs-400: 2rem;
    --fs-500: 2.8rem;
    --fs-600: 3rem;
    --fs-700: 4rem;
    --fs-800: 8.5rem;
  }
}

body {
  background: linear-gradient(to bottom, hsla(208deg, 12%, 20%, 0.1) 0, hsla(208deg, 12%, 5%, 0.75) 40%, hsla(208deg, 12%, 0%, 0.9) 100vh), url(assets/bg-kumiko.png), hsla(0deg, 1%, 39%, 0.85);
  background-blend-mode: normal, overlay, normal;
  background-size: auto auto, 102px 118px, auto auto;
  font-family: "Kanit", "sans-serif";
  color: hsl(248deg, 49%, 37%);
  min-height: 100vh;
  margin: 0;
}

.scroll-y {
  overflow-y: scroll;
  height: 100vh;
}

.scroll-snap-y section {
  scroll-snap-align: start;
}

.scroll-snap-x {
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  overflow-x: scroll;
}

.scroll-snap-x div {
  scroll-snap-align: start;
}

::-webkit-scrollbar {
  width: 1rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: hsl(344deg, 66%, 47%);
}

::-webkit-scrollbar-thumb {
  background: hsl(32deg, 95%, 58%);
  border: 1px solid hsl(344deg, 66%, 47%);
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(32deg, 67%, 51%);
}

::-webkit-scrollbar-button:single-button {
  display: block;
  background-color: hsl(32deg, 95%, 58%);
  border: 1px solid hsl(344deg, 66%, 47%);
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-width: 0 8px 12px 8px;
  border-color: transparent transparent hsl(32deg, 67%, 51%) transparent;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  border-width: 12px 8px 0px 8px;
  border-color: hsl(32deg, 67%, 51%) transparent transparent transparent;
}

@supports (scrollbar-color: red blue) {
  * {
    scrollbar-color: hsl(32deg, 95%, 58%) hsl(344deg, 66%, 47%);
  }
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.sr-only {
  visibility: hidden;
}

.carosel-control {
  display: none;
}
@media only screen and (max-width: 40em) {
  .carosel-control {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

h1 {
  font-size: var(--fs-800);
  line-height: var(--fs-700);
}

h2 {
  font-size: var(--fs-600);
  line-height: var(--fs-600);
  color: hsl(344deg, 66%, 47%);
  text-transform: uppercase;
  margin-inline: 1rem;
  text-shadow: 2px 2px 1px hsl(0deg, 0%, 15%);
}

h3 {
  font-size: var(--fs-400);
  line-height: var(--fs-300);
  color: hsl(32deg, 95%, 58%);
  text-transform: uppercase;
}

h4 {
  font-size: var(--fs-300);
  line-height: car(--fs-300);
  color: hsl(344deg, 66%, 30%);
  text-transform: uppercase;
}

a {
  color: hsl(344deg, 66%, 47%);
  text-decoration: none;
  padding-bottom: 0;
}

a:visited {
  color: hsl(252deg, 39%, 62%);
  -webkit-text-stroke: 2px hsl(248deg, 49%, 37%);
}

.caption {
  font-family: "Neon", sans-serif;
  font-size: var(--fs-200);
}

.card h2 {
  color: hsl(248deg, 49%, 37%);
  font-size: var(--fs-400);
  line-height: var(--fs-400);
  text-shadow: none;
  white-space: pre-wrap;
  margin-inline: 0;
}
@media only screen and (max-width: 40em) {
  .card h2 {
    font-size: var(--fs-300);
    line-height: var(--fs-300);
    white-space: normal;
  }
}
.card h3 {
  font-size: var(--fs-300);
  line-height: var(--fs-200);
  color: hsl(344deg, 66%, 47%);
  text-transform: uppercase;
  border-bottom: 2px solid hsl(344deg, 66%, 47%);
}
.card h4 {
  color: hsl(248deg, 49%, 37%);
}

.card-text {
  font-size: var(--fs-200);
  color: hsl(248deg, 49%, 37%);
  margin-bottom: 1rem;
  white-space: normal;
}

.tag {
  text-transform: uppercase;
  font-size: var(--fs-100);
}

.sr-only {
  font-size: 0.2rem;
}

.subtitle h3 {
  color: hsl(252deg, 39%, 62%);
  margin: 0 auto;
  text-align: center;
  font-size: var(--fs-500);
  line-height: var(--fs-600);
  margin-block: 0;
  -webkit-text-stroke: 1px hsl(248deg, 49%, 37%);
  text-shadow: 2px 2px 1px hsl(0deg, 0%, 15%);
}

.quote-text {
  font-family: "Neon", sans-serif;
  text-shadow: 0 0 3px hsl(344deg, 66%, 47%);
  font-size: var(--fs-400);
  color: hsl(32deg, 95%, 70%);
  justify-content: start;
  background-color: transparent;
}

.rotated {
  padding-top: 1rem;
  padding-bottom: 1em;
  margin-left: -20%;
  transform: rotate(-30deg);
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.site-link {
  text-align: justify;
  font-family: "Kanit", sans-serif;
  font-size: var(--fs-600);
  font-weight: 700;
  line-height: var(--fs-700);
  color: hsl(344deg, 66%, 47%);
  border: none;
  text-transform: capitalize;
  text-shadow: 2px 2px 1px hsl(0deg, 0%, 15%);
}

.site-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.side-link {
  text-align: justify;
  font-family: "Kanit", sans-serif;
  font-size: var(--fs-300);
  font-weight: 700;
  line-height: var(--fs-400);
  color: hsl(344deg, 66%, 47%);
  border: none;
  text-transform: capitalize;
  text-shadow: 2px 2px 1px hsl(0deg, 0%, 15%);
  padding-inline: 1rem;
  white-space: nowrap;
  overflow: ellipsis;
}

.side-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.nav-item {
  text-transform: capitalize;
  color: hsl(248deg, 49%, 37%);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: hsl(32deg, 95%, 58%);
  text-shadow: -2px -2px 0 hsl(32deg, 95%, 58%), 2px -2px 0 hsl(32deg, 95%, 58%), -2px 2px 0 hsl(32deg, 95%, 58%), 2px 2px 0 hsl(32deg, 95%, 58%);
  font-size: var(--fs-700);
  font-style: normal;
  font-weight: 700;
  line-height: 105.5%;
}
@media only screen and (max-width: 40em) {
  .nav-item {
    align-self: flex-start;
    padding-left: min(5%, 4rem);
  }
}
.nav-item > a {
  color: hsl(248deg, 49%, 37%);
  text-decoration: none;
  padding-bottom: 0;
}
.nav-item > a:visited {
  color: hsl(248deg, 49%, 37%);
}
.nav-item > a:hover, .nav-item > a:focus {
  color: hsl(32deg, 95%, 58%);
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: hsl(248deg, 49%, 37%);
  text-shadow: -1px -1px 0 hsl(248deg, 49%, 37%), 1px -1px 0 hsl(248deg, 49%, 37%), -1px 1px 0 hsl(248deg, 49%, 37%), 1px 1px 0 hsl(248deg, 49%, 37%);
}

h3 > a:visited {
  color: hsl(344deg, 66%, 47%);
}
h3 > a:hover {
  cursor: default;
}

.blog-text {
  color: hsl(248deg, 49%, 37%);
  max-width: 75ch;
  margin-left: 2ch;
  padding-inline: var(--fs-100);
}

label {
  text-transform: uppercase;
  font-weight: 600;
  color: hsl(248deg, 49%, 37%);
}

button[type=submit] {
  text-transform: uppercase;
  font-weight: 500;
}

.card-link {
  font-size: var(--fs-500);
  align-self: flex-start;
  margin-inline: auto;
}
.card-link a {
  color: hsl(344deg, 66%, 47%);
}
.card-link a:hover,
.card-link a:focus {
  border-bottom: none;
}

.card-cta {
  text-transform: uppercase;
  font-size: --fs-300;
}
.card-cta a {
  color: hsl(0deg, 0%, 85%);
}
.card-cta a:hover {
  color: hsl(32deg, 95%, 90%);
}

.case-body blockquote {
  font-family: "PT Sans Caption", sans-serif;
  font-size: var(--fs-200);
  font-weight: 600;
  color: hsl(252deg, 86%, 37%);
  background-color: hsl(32deg, 95%, 70%);
  padding-inline: 1rem;
  padding-block: 0rem;
  padding-top: 1rem;
  margin: 0;
  border-radius: 10px;
  border: 3px solid hsl(32deg, 67%, 51%);
}
.case-body blockquote blockquote {
  border: 0px solid transparent;
  background-color: hsl(344deg, 66%, 47%);
  color: hsl(32deg, 95%, 58%);
}

code {
  color: #000;
  font-family: "Source Code Pro", monospace;
  font-size: var(--fs-200);
  font-weight: 600;
}

pre {
  font-family: "Source Code Pro", monospace;
  font-size: var(--fs-200);
  font-weight: 600;
  background-color: hsl(248deg, 49%, 15%);
  color: hsl(32deg, 95%, 58%);
  text-indent: var(--fs-200);
  padding-inline: 0.5rem;
  border-radius: 5px;
}
pre code {
  white-space: pre-line;
  color: inherit;
}

.hljs-keyword {
  color: hsl(344deg, 66%, 90%);
}

.hljs-built_in {
  color: hsl(344deg, 66%, 90%);
}

.hljs-title {
  color: hsl(32deg, 95%, 70%);
}

.hljs-title.function_ {
  color: hsl(300deg, 100%, 50%);
}

.hljs-params {
  color: hsl(344deg, 66%, 47%);
}

.hljs-property {
  color: hsl(344deg, 66%, 70%);
}

.hljs-attr {
  color: hsl(252deg, 86%, 70%);
}

.hljs-string {
  color: hsl(32deg, 95%, 90%);
}

.hljs-variable {
  color: hsl(344deg, 66%, 47%);
}

.hljs-number {
  color: hsl(300deg, 100%, 90%);
}

.hljs-comment {
  color: hsl(248deg, 49%, 50%);
}

.list-group > h4 {
  color: hsl(32deg, 95%, 58%);
  font-weight: 700;
  font-size: var(--fs-400);
  letter-spacing: 0.05em;
  line-height: 1rem;
  text-transform: capitalize;
  text-align: center;
  padding-block: var(--fs-200);
}

a.blog-link {
  font-weight: 200;
  color: hsl(252deg, 86%, 15%);
}

a.blog-link:visited {
  color: hsl(344deg, 66%, 30%);
}

a.ul-link-l-to-r {
  position: relative;
}

a.ul-link-l-to-r:before {
  content: "";
  position: absolute;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

a.ul-link-l-to-r:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.App {
  position: relative;
  margin: 0 auto;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.text-justify {
  text-align: justify;
}

.flex-center {
  justify-content: center;
}

.nowrap {
  flex-wrap: nowrap;
}

.wrap {
  flex-wrap: wrap;
}

.grow {
  flex-grow: 2;
}

.shrink {
  flex-shrink: 2;
}

.vh100 {
  height: 100vh;
}

.max-h-90vh {
  max-height: 90vh;
}

.overflow-scroll {
  overflow: scroll;
}
@media only screen and (min-width: 40em) {
  .overflow-scroll {
    overflow: visible;
  }
}

.gap {
  gap: var(--gap, 3rem);
}

.gap-sm {
  gap: var(--gap-sm, 1rem);
}

.center {
  align-content: center;
}

.auto-center {
  margin: 0 auto;
}

.items-base {
  align-items: baseline;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.space-between {
  justify-content: space-between;
}

section {
  padding-bottom: 1rem;
  margin-bottom: -0.02rem;
}
section .content {
  max-width: 55ch;
  margin: 0 auto;
}

.grid-3-col {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}
@media only screen and (max-width: 40em) {
  .grid-3-col {
    grid-template-columns: 1fr;
  }
}

.col-1-1fr {
  grid-template-columns: 1fr;
}

.col-auto-flow {
  grid-auto-flow: column;
}

.align-items-center {
  align-items: center;
}

.align-items-top {
  align-items: flex-start;
  align-items: start;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-basis {
  flex-basis: auto;
}

.span-2 {
  grid-column: span 2;
}

.margin-top-sm {
  margin-top: 0.5rem;
}

.top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.about-group {
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  white-space: normal;
  background-color: hsl(0deg, 0%, 85%);
  border: 2px solid hsl(32deg, 95%, 58%);
  border-radius: 10px;
  flex-shrink: 0;
  position: relative;
}
@media only screen and (max-width: 40em) {
  .about-group {
    width: 81vw;
  }
}

.contact {
  margin-top: 6rem;
}

.grid {
  display: grid;
}

.nav {
  margin-bottom: 2rem;
}

.nav-list {
  display: flex;
  position: fixed;
  top: 0.25rem;
  left: 0.3rem;
  padding: 1rem 1rem;
  width: clamp(200px, 80%, 400px);
  background-color: hsla(32deg, 56%, 67%, 0.51);
  -webkit-backdrop-filter: blur(0.8rem);
          backdrop-filter: blur(0.8rem);
  z-index: 1000;
  flex-direction: column;
  padding: min(20vh, 2rem) 2em;
  margin-bottom: 0;
  overflow-y: auto;
  background: hsla(32deg, 56%, 67%, 0.51);
  border-right: 1px solid hsl(32deg, 95%, 58%);
  transform-origin: top left;
  transform: scaleX(0) scaleY(0);
  transition: transform 350ms ease-out;
}

@supports ((-webkit-backdrop-filter: blur(1em)) or (backdrop-filter: blur(1em))) {
  .nav-list {
    border-right: none;
    background: hsla(32deg, 56%, 67%, 0.51);
    -webkit-backdrop-filter: blur(13px);
            backdrop-filter: blur(13px);
  }
}
.nav-list[data-visible=true] {
  transform-origin: top left;
  transform: scaleX(100%) scaleY(100%);
}

nav {
  padding: 0;
  margin: 0;
}
nav ul {
  text-decoration: none;
}

.case {
  margin-top: 1rem;
}
.case h2 {
  padding-top: 1rem;
}
.case h3 {
  padding-top: 1rem;
}

.sidebar {
  display: none;
  max-height: 400px;
  border-radius: 12px;
}
@media only screen and (min-width: 40em) {
  .sidebar {
    display: block;
  }
}
.sidebar ul {
  padding: 0;
  list-style: none;
}

.case-body {
  padding: 1.5rem;
  max-width: 65ch;
  margin: 0 auto;
}
.case-body h2 {
  padding-bottom: 1rem;
  margin-left: -1rem;
  font-size: var(--fs-400);
  color: hsl(344deg, 66%, 47%);
}

.case-image {
  border-radius: 10px 10px 0 0;
}

.case-content {
  width: 100%;
}

.case blockquote {
  padding-bottom: 2rem;
}

.case-list {
  padding: 1rem;
}

.list-group {
  background-color: rgba(61, 48, 140, 0.42);
  border: 1px solid rgba(250, 157, 48, 0.8);
  border-radius: 10px;
}

.site-header {
  position: relative;
}
@media only screen and (max-height: 2000px) {
  .site-header {
    min-height: 120vh;
  }
}
@media only screen and (max-height: 675px) {
  .site-header {
    min-height: 135vh;
  }
}

@media only screen and (min-width: 40em) {
  .navigation {
    grid-column-start: 1;
    grid-column-end: 2;
    justify-self: center;
    align-self: center;
    padding: 0;
    margin: 0;
    margin-top: 3rem;
  }
}

@media only screen and (min-width: 40em) {
  .medalion-group {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
  }
}

.oni {
  margin: 0 auto;
}
@media only screen and (min-width: 40em) {
  .oni {
    padding: 3rem;
  }
  .oni > img {
    max-width: 700px;
    margin: 0 auto;
  }
}

.home {
  max-width: 1300px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media only screen and (max-width: 40em) {
  .home {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.home .navigation {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 40em) {
  .home .navigation {
    text-align: justify;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.home-container {
  position: relative;
}

.medalion {
  justify-self: center;
  color: hsl(32deg, 95%, 58%);
  font-size: var(--fs-800);
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  -webkit-text-stroke: 2px hsl(344deg, 66%, 47%);
  line-height: 64%;
  text-shadow: 2px 2px 4px hsl(0deg, 0%, 15%);
  background: border-box radial-gradient(circle, hsl(252deg, 39%, 62%) 0%, rgba(110, 9, 121, 0.175) 35%, rgba(0, 0, 0, 0) 60%);
}
@media only screen and (max-width: 40em) {
  .medalion {
    padding: 0.5rem;
  }
}

.subtitle {
  justify-self: center;
  align-self: center;
  margin-top: 3.5rem;
}

.link-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: "PT Sans Caption", sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  align-items: baseline;
  color: hsl(32deg, 95%, 58%);
  font-size: var(--fs-200);
  text-align: justify;
  margin-inline: 0.2rem;
  padding-inline: var(--fs-200);
}

.social-links {
  position: fixed;
  top: 3rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
@media only screen and (max-width: 40em) {
  .social-links {
    position: relative;
    top: unset;
    right: unset;
    flex-direction: row;
    gap: 1rem;
  }
}

.content-div {
  height: 200vh;
}

.btn {
  font-weight: 600;
  color: hsl(32deg, 95%, 58%);
  padding: 0.6rem 2rem 0.6rem;
  text-shadow: 1px 1px hsl(0deg, 0%, 15%);
}

.raise {
  transition: 0.25s;
}

.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em hsl(344deg, 66%, 70%);
  transform: translateY(-0.25em);
}

.scroll-to-top :hover {
  cursor: pointer;
}

#up-arrow {
  fill: hsl(32deg, 95%, 58%);
  stroke: hsl(344deg, 66%, 47%);
  stroke-width: 3;
}

.carosel-control-button {
  fill: hsl(32deg, 95%, 58%);
  stroke: hsl(344deg, 66%, 47%);
  stroke-width: 3;
  background-color: transparent;
  padding: 0;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.hamburger {
  position: fixed;
  padding: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 0;
  height: 37.5px;
  top: 0.25rem;
  bottom: 2rem;
  left: 0.25rem;
  background-position: center;
  background-color: transparent;
  background-image: url(assets/hamburger.svg);
  aspect-ratio: 1;
  z-index: 9999;
  border: none;
}

.hamburger[aria-expanded=true] {
  background-image: url(assets/hamburger-close.svg);
}

.hero,
.tutorial {
  gap: var(--gap-sm);
  grid-column: span 2;
}
@media only screen and (max-width: 40em) {
  .hero,
.tutorial {
    width: 80vw;
  }
}

.side-kick {
  gap: var(--gap-sm);
  width: 100%;
}
@media only screen and (max-width: 40em) {
  .side-kick {
    width: 98vw;
  }
}

.card {
  background-color: hsl(0deg, 0%, 85%);
  border: 2px solid hsl(32deg, 95%, 58%);
  border-radius: 10px;
  flex-shrink: 0;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: relative;
}
.card .card-text {
  max-width: 60ch;
  padding: 0.1rem;
  margin-top: 0.5rem;
  line-height: 1.7rem;
  word-break: break-word;
}
.card .card-content {
  display: grid;
  padding-top: 0.5rem;
  padding-left: 2rem;
  padding-right: 0;
  padding-bottom: 0.3rem;
}
@media only screen and (min-width: 40em) {
  .card .card-content {
    grid-template-columns: 4fr 1fr;
  }
}
@media only screen and (max-width: 40em) {
  .card .card-content {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 40em) {
  .card .card-content {
    padding-top: 0.5rem;
    padding-left: 0.1rem;
    padding-right: 0;
  }
}
.card .card-condensed {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.3rem;
}
@media only screen and (max-width: 40em) {
  .card .card-condensed {
    padding-top: 0.5rem;
    padding-left: 0.1rem;
    padding-right: 0;
  }
}
.card .image-container {
  position: relative;
  width: 100%;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
@media only screen and (max-width: 40em) {
  .card .image-container {
    height: 10rem;
  }
}
.card .image-container img {
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left top;
     object-position: left top;
}

.content-group {
  white-space: normal;
  width: 81vw;
  overflow-x: visible;
}
@media only screen and (min-width: 40em) {
  .content-group {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    flex-direction: column;
    -ms-scroll-snap-type: none;
        scroll-snap-type: none;
    overflow-x: visible;
  }
}

.deck-box {
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  width: 81vw;
  -ms-scroll-snap-type: x mandatory;
      scroll-snap-type: x mandatory;
  overflow-x: scroll;
}
@media only screen and (min-width: 40em) {
  .deck-box {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    flex-direction: column;
    -ms-scroll-snap-type: none;
        scroll-snap-type: none;
    overflow-x: visible;
  }
}

.deck-box div {
  scroll-snap-align: start;
}

.deck-box > .card {
  margin-right: 0;
}
@media only screen and (max-width: 40em) {
  .deck-box > .card {
    width: 80vw;
    margin-right: 1rem;
  }
}

.card-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 48px;
}
@media only screen and (max-width: 40em) {
  .card-sidebar {
    display: none;
    min-width: 0px;
  }
}

.tag {
  color: hsl(252deg, 39%, 62%);
  padding-inline: 0.2rem;
  padding-block: 0.1rem;
  margin-inline: 0.05rem;
  background-color: hsl(32deg, 95%, 58%);
  border-radius: 2px;
  font-size: var(--fs-100);
  font-weight: 800;
}

.card-cta {
  border-radius: 5px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0.5em 1em;
  margin: 1em 1em 0 0;
  background-color: hsl(344deg, 66%, 47%);
}

.blog-block {
  position: relative;
  border-radius: 12px;
  padding: 2em;
  margin-top: 3em;
  margin-bottom: 6em;
  background-color: hsl(0deg, 0%, 85%);
}

.long-block {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-bottom: 1em;
  cursor: pointer;
}

.short-block {
  position: relative;
  height: 5rem;
  margin-bottom: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.short-block:hover {
  cursor: pointer;
}

.short-block::after {
  content: " more...";
  font-size: large;
  font-weight: 900;
  color: hsl(344deg, 66%, 47%);
  position: absolute;
  right: 0;
  bottom: 0;
  padding-inline: 1em;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(230, 230, 230) 15%);
}

input[type=text], [type=email], select, textarea {
  width: 100%;
  padding: 12px;
  border: 2px solid hsl(344deg, 66%, 90%);
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;
}

input:focus, input:hover, textarea:focus, textarea:hover {
  border: 2px solid hsl(344deg, 66%, 30%);
}

textarea {
  resize: none;
  overflow-y: scroll;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type=submit] {
  background-color: hsl(344deg, 66%, 47%);
  color: hsl(0deg, 0%, 85%);
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  margin-block: 5px;
  margin-right: 10px;
}

.form-container {
  border-radius: 5px;
  background-color: hsl(0deg, 0%, 70%);
  padding: 20px;
  margin-block: 1em;
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

@media only screen and (min-width: 40em) {
  .form-row {
    width: 50ch;
  }
}

.form-row:after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .col-25, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}/*# sourceMappingURL=App.css.map */