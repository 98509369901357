@use "../abstracts" as *;

.btn {
  font-weight: 600;
  color: $orange-3;
  padding: 0.6rem 2rem 0.6rem;
  text-shadow: 1px 1px $neutral-1;
}

.raise {
  transition: 0.25s;
}
.raise:hover,
.raise:focus {
  box-shadow: 0 0.5em 0.5em -0.4em $magenta-4;
  transform: translateY(-0.25em);
}

.scroll-to-top :hover {
  cursor: pointer;
}

#up-arrow {
  fill: $orange-3;
  stroke: $magenta-3;
  stroke-width: 3;
}

.carosel-control-button {
  fill: $orange-3;
  stroke: $magenta-3;
  stroke-width: 3;
  background-color: transparent;
  padding: 0;
  border: none;
  margin-left: 5px;
  margin-right: 5px;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.hamburger {
  position: fixed;
  padding: 0;
  background-repeat: no-repeat;
  cursor: pointer;
  padding: 0;
  height: 37.5px;
  top: 0.25rem;
  bottom: 2rem;
  left: 0.25rem;
  background-position: center;
  background-color: transparent;
  background-image: url(assets/hamburger.svg);
  aspect-ratio: 1;
  z-index: 9999;
  border: none;
}

.hamburger[aria-expanded="true"] {
  background-image: url(assets/hamburger-close.svg);
}
