@use 'breakpoints' as *;
@use 'colors' as *;

/* media query mixin */
@mixin mq($key){
    $size: map-get($breakpoints, $key );
    @media only screen and (min-width: $size){
        @content;
    }
}

@mixin mqHeight($key){
	$size: map-get($breakpoints, $key );
	@media only screen and (max-height: $size){
		@content;
	}
}


/* media query mixin for max-width */
@mixin mqMax($key){
    $size: map-get($breakpoints, $key );
    @media only screen and (max-width: $size){
        @content;
    }
}


/* Start of text3d mixin */
@mixin text3d ($color: $neutral-4, $x: 1, $y: -1,  $darken: 1) {
	color: $color;
	text-shadow: 0px 0px 0 darken($color, 14%),
				$x* 1px $y* 1px 0 darken($color, $darken * 15),
				$x* 2px $y* 2px 0 darken($color, $darken * 16),
				$x* 3px $y* 3px 0 darken($color, $darken * 17),
				$x* 4px $y* 4px 0 darken($color, $darken * 18),
				$x* 5px $y* 5px 0 darken($color, $darken * 19),
				$x* 6px $y* 6px 0 darken($color, $darken * 20),
				$x* 7px $y* 7px 0 darken($color, $darken * 21),
				$x* 8px $y* 8px 0 darken($color, $darken * 22),
				$x* 9px $y* 9px 0 darken($color, $darken * 23),
				$x* 10px $y* 10px 0 darken($color, $darken * 24),
				$x* 11px $y* 11px 0 darken($color, $darken * 25), 
				$x* 12px $y* 12px 0 darken($color, $darken * 26),
				$x* 13px $y* 13px 12px rgba(0,0,0,0.55), 
				$x* 13px $y* 13px 1px rgba(0,0,0,0.5);
}
/* End of text3d mixin */