

$neutral-1: hsl(0, 0%, 15%);
$neutral-2: hsl(0, 0%, 30%);
$neutral-3: hsl(0, 0%, 50%);
$neutral-4: hsl(0, 0%, 70%);
$neutral-5: hsl(0, 0%, 85%);

$blue-1: hsl(248, 49%, 15%);
$blue-2: hsl(248, 49%, 37%);
$blue-3: hsl(248, 49%, 50%);
$blue-4: hsl(248, 49%, 70%);
$blue-5: hsl(248, 49%, 90%);

$orange-1: hsl(32, 95%, 15%);
$orange-2: hsl(32, 67%, 51%);
$orange-3: hsl(32, 95%, 58%);
$orange-4: hsl(32, 95%, 70%);
$orange-5: hsl(32, 95%, 90%);

$magenta-1: hsl(344, 66%, 15%);
$magenta-2: hsl(344, 66%, 30%);
$magenta-3: hsl(344, 66%, 47%);
$magenta-4: hsl(344, 66%, 70%);
$magenta-5: hsl(344, 66%, 90%);

$pink-1: hsl(300, 100%, 15%);
$pink-2: hsl(300, 100%, 30%);
$pink-3: hsl(300, 100%, 50%);
$pink-4: hsl(300, 100%, 70%);
$pink-5: hsl(300, 100%, 90%);

$purple-1: hsl(252, 86%, 15%);
$purple-2: hsl(252, 86%, 37%);
$purple-3: hsl(252, 39%, 62%);
$purple-4: hsl(252, 86%, 70%);
$purple-5: hsl(252, 86%, 90%);

