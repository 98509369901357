@use '../abstracts/' as *;

.App{
  position: relative;
  margin: 0 auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
  }
  
  .flex-col {
    display: flex;
    flex-direction: column;
  }

  .text-justify{
    text-align: justify;
  }
  
  .flex-center{
    justify-content: center;
  }
  
  .nowrap {
    flex-wrap: nowrap;
  }
  
  .wrap { 
    flex-wrap: wrap;
  }
  
  .grow {
    flex-grow: 2;
  }
  
  .shrink {
    flex-shrink: 2;
  }
  

  .vh100{
    height: 100vh;
  }
  .max-h-90vh{
    max-height: 90vh;
  }

  .overflow-scroll{
    overflow: scroll;
    @include mq("small"){
        overflow: visible;
    }
  }
  
  .gap {
    gap: var(--gap, 3rem);
  }

  .gap-sm {
    gap: var(--gap-sm, 1rem);
  }
  
  .center {
    align-content: center;
  }
  
  .auto-center {
    margin: 0 auto;
  }
  
  .items-base{
    align-items: baseline;
  }

  .items-end{
    align-items: flex-end;
  }
  .items-center {
    align-items: center;
  }
  
  .justify-start {
    justify-content: start;
  }
  
  .justify-end {
    justify-content: end;
  }

  .space-between{
      justify-content: space-between;
  }

section{
    padding-bottom: 1rem;
    margin-bottom: -.02rem;

    & .content{
        max-width: 55ch;
        margin: 0 auto;
        
    }
}


.grid-3-col{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;

  @include mqMax("small"){
    grid-template-columns: 1fr;
  }

}

.col-1-1fr{
  grid-template-columns: 1fr;
}

.col-auto-flow{
  grid-auto-flow: column;
}

.align-items-center{
  align-items: center;
}

.align-items-top{
  align-items: flex-start;
  align-items: start;
 
}


.flex-grow{
  flex-grow: 1;
}

.flex-shrink{
  flex-shrink: 1;
}

.flex-no-shrink{
  flex-shrink: 0;
}

.flex-basis{
  flex-basis: auto;
}

.span-2{
  grid-column: span 2;
}
.margin-top-sm{
  margin-top: .5rem;
}


.top-button{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;

}


.about-group{
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
  white-space: normal;
  background-color: hsl(0deg, 0%, 85%);
  border: 2px solid hsl(32deg, 95%, 58%);
  border-radius: 10px;
  flex-shrink: 0;
  position: relative;

  @include mqMax("small"){
    width: 81vw;
  }
}

.contact { 
  margin-top: 6rem;
}




  